import {differenceInMonths, format, parse} from 'date-fns';
import {stkRtPrecision} from '../data/const';

export const ymdFormat = 'yyyy.MM.dd';

export const YMD = 'yyyyMMdd';

export const EMPTY = '-';

export function ymdToDate(ymd: string | null | undefined): Date | null {
    if (!ymd) return null;

    if (ymd.length === 10)
        return parse(ymd, 'yyyy-MM-dd', 0);
    return parse(ymd, YMD, 0);
}

export function dateFac (ymd: Date) {
    return differenceInMonths(new Date(), ymd) + 1;
}

export function dateFacYear (ymd: Date|null) {
    if (!ymd)
        return "0";
    return (dateFac(ymd) / 12).toFixed(1);
}

export function ymdToDateString(ymd: string | undefined, fmt: string = ymdFormat) {
    if (!ymd) return '';
    if (ymd.length === 1) return '';

    if (ymd.length > 10) return ymd;
    ymd = ymd.replace(/^(\d{6})00$/, '$1'); //20180600 같은 값이 있음. Safari에서 look behind 지원 안 함
    if (ymd.length === 6) return ymd.substring(0, 4) + '.' + ymd.substring(4);
    if (ymd.length === 5) return ymd.substring(0, 4) + '.0' + ymd.substring(4);
    if (ymd.length === 4) return ymd + '.01';
    const date = ymdToDate(ymd);
    if (!date) return '';
    return format(date, fmt);
}

export function toDateString(date: Date, fmt: string = ymdFormat) {
    return format(date, fmt);
}

export function numToDateString(date: number, fmt: string = ymdFormat) {
    return format(new Date(date), fmt);
}

export function toBirthAndSex(pid: string): [Date | null, boolean | null] {
    if (pid) {
        if (pid.length === 8) {
            const birth = ymdToDate(pid);
            return [birth, null];
        }
        else if (pid.length >= 7) {
            const s1 = pid.substring(0, 6);
            const s2 = pid.substring(6, 7);
            const ymd = (s2 === '1' || s2 === '2' ? '19' : '20') + s1;
            const birth = ymdToDate(ymd);
            return [birth, s2 === '2' || s2 === '4'];
        } else if (pid.length === 6) {
            const y = Number(pid.substring(0, 2));
            const ymd = (y > 40 ? '19' : '20') + pid;
            const birth = ymdToDate(ymd);
            return [birth, null];
        }
    }
    return [null, false];
}

export function getAge0(birth: Date, refDate = new Date()) {//만 나이
    let age = refDate.getFullYear() - birth.getFullYear();
    const birth2 = new Date(birth);
    birth2.setFullYear(refDate.getFullYear()); //올해 생일
    if (refDate < birth2) {
        --age; //생일이 안 지난 경우
    }
    return age;
}

export function getAge(birth: Date): string {//만 나이
    return '만' + getAge0(birth) + '세';
}

export function toBirthAndAge0(birth: Date | null): string {
    return birth ? toDateString(birth) + ' (' + getAge(birth) + ')' : '';
}

export function toBirthAndAge0Mask(birth: Date | null): string {
    return birth ? `${toDateString(birth).substring(0, 7)}.** (${getAge(birth)})` : '';
}

export function toBirthAndAge(pid: string): string {

    const [birth] = toBirthAndSex(pid);
    return toBirthAndAge0(birth);
}

export function toBirthAndAgeEmptyMask(pid: string, mask: boolean): string {

    const [birth] = toBirthAndSex(pid);

    if (birth) {
        if (mask) {
            const bs = toDateString(birth);
            return `${bs.substring(0, bs.length - 2)}** (${getAge(birth)})`;
        }
        return toBirthAndAge0(birth);
    }

    else
        return "****-**-**"
}

export function formatBzno(bzno: string): string {
    return bzno ? bzno.substring(0, 3) + '-' + bzno.substring(3, 5) + '-' + bzno.substring(5) : '';
}

export function formatPid(pid: string): string {
    return pid ? pid.substring(0, 6) + '-' + pid.substring(6) : '';
}

export function formatPhoneNum(phone: string): string {
    if (!phone) return '';
    let s = phone.replace(/\s+/g, '');
    switch (s.length) {
        case 8:
            return s.substring(0, 4) + '-' + s.substring(4)
        case 9:
            return s.substring(0, 2) + '-' + s.substring(2, 5) + '-' + s.substring(5)
        case 10:
            if (s.substring(0, 2) === '02') {
                return s.substring(0, 2) + '-' + s.substring(2, 6) + '-' + s.substring(6)
            } else {
                return s.substring(0, 3) + '-' + s.substring(3, 6) + '-' + s.substring(6)
            }
        default:
            if (s.length >= 11) {
                return s.substring(0, 3) + '-' + s.substring(3, 7) + '-' + s.substring(7)
            } else {
                return s
            }
    }
}

export type NumParam = {
    num: number | string | undefined
    type?: "table" | "graph" | "input" | "txt"
    unit?: string
    precision?: number
    empty?: string

    mask?: boolean;
}

export function formatNum({num, unit = '', empty = EMPTY, type = 'table'}: NumParam): string {
    if (num !== 0 && !num) return empty!;
    if (num === EMPTY) return num;
    const n = Number(num);
    if (type === 'table' && n === 0) return empty!;
    return n.toLocaleString() + unit;
}

export function formatNum2({num, empty = EMPTY, type = 'table', mask}: NumParam): string {
    if (num !== 0 && !num) return empty!;
    const n = Number(num);
    if (type === 'table' && n === 0) return EMPTY;
    let mn = n < 0 ? ((-n).toLocaleString()) : n.toLocaleString();

    if (mask) {
        if (mn.length > 3) {
            mn = mn.substring(0, mn.length - 3).replaceAll(/[0-9]/g, "*") + mn.substring(mn.length - 3);
        }
    }

    return n < 0 ? '(' + mn + ')' : mn;
}

export const formatNum1000 = ({num, type = 'table'}: NumParam) => {
    if (num !== 0 && !num) return EMPTY;
    const n = Number(num);
    if (type === 'table' && n === 0) return EMPTY;
    return (Math.round(n / 1000)).toLocaleString();
}

export const formatNum10000 = ({num, unit = '만원', type = 'table'}: NumParam) => {
    if (num !== 0 && !num) return EMPTY;
    const n = Number(num);
    if (type === 'table' && n === 0) return EMPTY;
    return (Math.round(Number(num) / 10000)).toLocaleString() + unit;
}

export const round = (num: number, precision: number = 0) => {
    return Number(Math.round(Number(num + 'e' + precision)) + 'e' + (-precision));
}

export const roundWithDecimalZero = (num: number, precision: number = 0) => {
    const value = round(num, precision);
    let decNumber = "";

    if (value % 1 === 0 && precision > 0) {
        decNumber += ".";

        let i = 0;
        while (i < precision) {
            decNumber += "0";
            i++;
        }
    }

    return `${value}${decNumber}`;
}

export const toHundredMillion = ({num, unit = '억원', precision = 2, empty = EMPTY, type = 'table'}: NumParam) => {
    if (num !== 0 && !num) return empty;
    const n = Number(num);
    if (type === 'table' && n === 0) return EMPTY;

    return (n / 1e8).toFixed(precision!) + unit;
}

//소수점 n자리로 버림
export const toHundredMillion2 = ({num, unit = '억원', precision = 2, empty = EMPTY, type = 'table'}: NumParam) => {
    if (num !== 0 && !num) return empty;
    const n = Number(num);
    let pre = Number('1' + [...Array(precision)].map((it) => '0').join(''));
    if (type === 'table' && n === 0) return EMPTY;
    return Math.floor((n / 1e8) * pre)/pre + unit;
}

export const toHundredMillionSm = ({num, unit = '억원', precision = 2, empty = EMPTY, type = 'table'}: NumParam) => {
    if (num !== 0 && !num) return empty;
    const n = Number(num);
    if (type === 'table' && n === 0) return EMPTY;

    if (n > 1e8)
        return (n / 1e8).toFixed(precision!) + unit;
    else
        return formatNum10000({num: num, type: type});
}

export const toBigCurrency = ({num, unit = '원', empty = EMPTY, type = 'table', precision = 2}: NumParam) => {
    if (num !== 0 && !num) return empty;
    const n = Number(num);
    const abs = Math.abs(n);
    if (abs === 0 && type === 'table') return empty;
    else if (abs >= 1e8) return round(n / 1e8, precision).toLocaleString() + '억' + unit;
    else if (abs >= 1e4) return round(n / 1e4, 0).toLocaleString() + '만' + unit;
    else return n.toLocaleString() + unit;
}

export const toNum = (num: any) => num != null ? Number(num) : undefined;

export const div = (num: number | undefined | null, div: number | undefined | null) => {
    return num != null && div ? Math.round(num / div) : undefined;
}

export const multiply = (a: number | undefined | null, b: number | undefined | null) => {
    return a != null && b != null ? a * b : undefined;
}

export const ratio = (a: any, b: any) => {
    if (b === 0 || b === '' || b === '0')
        return 0;

    if (b && (a === 0 || a)) return Number(a) / Number(b);
    return undefined;
}

export const hyphen = (str: any) => {
    return str !== 0 && !str ? EMPTY : str;
}

export const toPercent0 = (num: number | undefined | null) => {
    return num != null ? num * 100 + '%' : EMPTY;
}

export const toPercent = (val: number | undefined | null, unit = '%', precision = 1) => val != null ? (val * 100).toFixed(precision) + unit : EMPTY;

export const formatPercent = (val: number | undefined | null, precision = stkRtPrecision) => val ? `${roundWithDecimalZero(val, precision)}%` : EMPTY;

//소수점 자리수 맞추기
export const formatPercent2 = (val: number | undefined | null, point:number = 2) => val != null ? val.toFixed(point) + '%' : EMPTY;

export const shortAddr = (addr: string) => {
    // return addr ? addr.split(' ').slice(0, 2).join(' ') : '';
    return addr ? addr.split(' ')[0] : '';
}
/**
 * 받침 유뮤에 따라 조사 선택
 * @param str
 * @param pp 을를, 이가
 */
export const setPostposition = (str: string, pp: string) => {
    if (str.length === 0) return str + pp[0];
    const c = str.charCodeAt(str.length - 1);
    if (c < 44032) return str + pp[0];
    const jongsung = (c - 44032) % 28;
    return str + pp[jongsung > 0 ? 0 : 1];
}

export const numberOnly = (s: string, negative: boolean = false) => {
    if (negative) return s.replaceAll(/[^-\d]/g, '').replaceAll(/(.)-/g, '$1');
    return s.replaceAll(/\D/g, '');
}

export const addComma = (num: number) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function makeSearchDataQs(searchData: { [key: string]: string | string[] | undefined }): string {
    let rs: string = "";

    if (searchData) {
        let qs = [];

        for (let key in searchData) {
            if (searchData[key]) {
                if (Array.isArray(searchData[key])) {
                    const arr = searchData[key] as string[];
                    const finalVal = arr.map((v) => encodeURIComponent(v.replaceAll(",", "&comma;"))).join(",")


                    qs.push(`${key}=${finalVal}`);
                }
                else {
                    qs.push(`${key}=${encodeURIComponent(searchData[key]! as string)}`);
                }

            }
        }

        if (qs.length > 0)
            rs = "&" + qs.join("&");
    }

    return rs;
}

export function stripCenterTime(timeStr: string) {
    return timeStr.replaceAll('T', ' ');
}

export const hasNewVersion = (current:string,latest:string) => {
    if(!current) return true;
    let v1:number[] = current.split(".").map((num) => parseInt(num));
    let v2:number[] = latest.split(".").map((num) => parseInt(num));
    if(v2[0]>v1[0])return true;
    else if(v2[0]<v1[0])return false;
    if(v2[1]>v1[1])return true;
    else if(v2[1]<v1[1])return false;
    if(v2[2]>v1[2])return true;
    else if(v2[2]<v1[2])return false;
    if(v2[3]>v1[3])return true;
    else return false;
}

export function formatTime(seconds:number) {
    // 분과 초를 계산합니다.
    const minutes = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);

    // 분과 초를 두 자리로 포맷합니다.
    const minutesStr = minutes.toString().padStart(2, '0');
    const secsStr = secs.toString().padStart(2, '0');

    return `${minutesStr}:${secsStr}`;
}

export function parseDurationToSeconds(timeString: string): number {
    // 문자열을 ":"로 분리
    const parts = timeString.split(":");
    if (parts.length !== 2) {
        throw new Error("Invalid time format, expected MM:SS");
    }

    // 분과 초를 파싱
    const minutes = parseInt(parts[0], 10);
    const seconds = parseInt(parts[1], 10);

    // 유효한 숫자인지 확인
    if (isNaN(minutes) || isNaN(seconds)) {
        throw new Error("Invalid time format, expected numeric MM:SS");
    }

    // 전체 초 반환
    return minutes * 60 + seconds;
}

export function calcPerPrice(price: number, cnt: number): number {
    return Math.round(price / cnt / 1000) * 1000;
}

export function checkAuthType (fieldName: string, value?: {[key:string]: boolean}): boolean {

    if (!value) return false;

    if (value.hasOwnProperty(fieldName)) {
        return value[fieldName];
    }

    return false;
}